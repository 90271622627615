<script setup lang="ts">
   const { sessionDetailAsClient } = storeToRefs(useClientVideoStore());
   const { sessionDetailAsTherapist } = storeToRefs(useTherapistVideoStore());
   import { ClockIcon } from '@heroicons/vue/24/outline';
   const { useTimeFormat } = useTimeComposable();
   const { locale } = useI18n();
   const props = defineProps<{
      data?: {
         image?: string;
         name?: string;
         date?: string;
         time?: string;
      };
      isClient?: boolean;
   }>();

   const { t } = useI18n();
   const sessionDetail = computed(() => {
      if (props.isClient) {
         return sessionDetailAsClient.value;
      } else {
         return sessionDetailAsTherapist.value;
      }
   });
</script>
<template>
   <div
      class="py-4 bg-primary-200 min-h-32 rounded mb-6 flex flex-col items-center gap-3"
   >
      <div class="flex gap-4 items-center min-w-56">
         <BaseAvatar
            :image-id="
               isClient ?
                  sessionDetail?.therapist?.image || data?.image
               :  sessionDetail?.client?.image || data?.image
            "
            role="therapist"
            classes="rounded-full object-cover w-14  h-14 "
            :alt="`User  Picture`"
         />
         <div class="flex flex-col gap-2">
            <p class="text-sm font-medium">
               {{
                  isClient ?
                     sessionDetail?.therapist?.name || data?.name
                  :  sessionDetail?.client?.name || data?.name
               }}
            </p>
            <p class="text-xs text-gray-200">{{ t('zoom.yourTherapist') }}</p>
         </div>
      </div>
      <div class="flex gap-4 items-center min-w-56">
         <div class="w-14 h-14 flex flex-col text-center">
            <p class="font-medium text-xl md:text-3xl">
               {{
                  $dayjs(sessionDetail?.date || data?.date)
                     .locale(locale || 'en')
                     .format('DD')
               }}
            </p>
            <p class="text-xs">
               {{
                  $dayjs(sessionDetail?.date || data?.date)
                     .locale(locale || 'en')
                     .format('MMM')
               }}
            </p>
         </div>
         <div class="flex flex-col gap-2">
            <p>{{ t('zoom.OnlineSession') }}</p>
            <div class="flex items-center gap-2 text-gray-200">
               <ClockIcon class="h-4 w-4 text-primary-500"></ClockIcon>
               <p class="text-xs">
                  {{
                     useTimeFormat(
                        `${sessionDetail?.date || data?.date}T${sessionDetail?.time || data?.time}`
                     )
                  }}

                  -
                  {{
                     useTimeFormat(
                        $dayjs
                           .utc(
                              `${sessionDetail?.date || data?.date}T${sessionDetail?.time || data?.time}`
                           )
                           .add(50, 'm')
                     )
                  }}
               </p>
            </div>
         </div>
      </div>
   </div>
</template>
